.ant-image-preview-root{
    display: none;
}
.ant-image-preview-operations-wrapper{
    display: none;
}
.ant-image-mask{
    display: none !important;
}
.ant-menu-dark{
    background: #003876 !important;
}
.ant-layout .ant-layout-sider{
    background: #003876 !important;
}
.ant-menu-item-selected{
    background-color: #bfcc5c !important;
}
.anticon-menu-fold,
.ant-btn-icon {
    color: #fff !important;
}
.ant-menu{
    font-size: 16px !important;
}
.ant-menu-item-icon{
    font-size: 16px !important;
}
.ant-popover-inner{
    background-color: #bfcc5c !important;
    padding: 5px 5px !important;
}
.ant-btn-link{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 20px !important;
}