.custom-cursor-pointer {
  cursor: pointer;
}

.MuiDataGrid-columnHeadersInner, .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
}

.MuiDataGrid-columnHeadersInner div {
  width: 100% ;
}

.MuiDataGrid-virtualScrollerRenderZone div {
  width: 100% !important;
}

.MuiDataGrid-cell:last-child {
  display: none !important;
}

.MuiDataGrid-columnHeader, .MuiDataGrid-cell {
  min-width: 150px !important;
  max-width: none !important;
  flex-grow: 1 !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.MuiDataGrid-cellContent {
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin: 0 !important;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
