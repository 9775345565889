.datePickOuter {
  position: relative;
}

.pandl-drop {
  margin: 10px 0;
  display: flex;
  justify-content: start;
}

.parent-calender {
  top: -8px;
  position: absolute;
  right: -24em;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* .selectDateBtn {
  width: 332px;
} */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* 
.ant-col {
  padding: 12px 0px !important;
} */

.anticon-check-circle {
  color: #24d153 !important;
  font-size: 18px;
}

.anticon-close-circle {
  color: red !important;
  font-size: 18px;
}

.ant-table-column-sorter-inner>.anticon-caret-up {
  color: #000 !important;
}

.ant-table-column-sorter-inner>.anticon-caret-down {
  color: #000 !important;
}

.ant-drawer-content-wrapper {
  width: 50% !important;
}

.drawer-table-cell {
  width: 45%;
}

.datepi {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.status-btn {
  background-color: #aee9cc !important;
}

.Custom-Drawer .ant-drawer-header {
  padding: 0px !important;
  border-bottom: none !important;
  display: none;
}

.custom-datepicker .anticon {
  color: #003876 !important;

}

.custom-datepicker .ant-picker-input {
  cursor: pointer !important;
}

.ant-btn-icon>.anticon-close {
  color: #000 !important;
}

.ant-modal-close-x>.ant-modal-close-icon {
  color: #000 !important;
}

.tablecolumns {
  width: 100%;
}

.tablecolumn2 table {
  table-layout: fixed !important;
  width: 100%;
}

.tablecolumn2 {
  border: 1px solid #cbcbcb;
  overflow-y: scroll;
  height: 350px;
}

.tablecolumn2 td {
  border-right: 1px solid #cbcbcb !important;
  border-bottom: 1px solid #cbcbcb !important;
  vertical-align: baseline;
}

.tablecolumn2 tr td:last-child {
  border-right: none !important;
  padding: 16px 8px !important;
  /* Remove border from the last td in each row */
}

.tablecolumn2::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
  background-color: #f0f0f0;
  /* Set the background color of the scrollbar track */
  border-radius: 5px;
}

.tablecolumn2::-webkit-scrollbar-thumb {
  background-color: #777879;
  /* Set the color of the scrollbar thumb */
  border-radius: 5px;
}

/* Customize scrollbar track */
.tablecolumn2::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Set the color of the scrollbar track */
  border-radius: 5px;
}

.tablecolumns {
  border: 1px solid rgb(185, 182, 182);
}

@media (max-width: 992px) {
  .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

@media (max-width: 768px) {
  .tablecolumns {
    overflow-x: auto;
    /* or overflow-x: scroll; */
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-tree-icon__customize {
  float: right;
  margin-left: 10px;
}

/* Custom CSS to display icon on the right side */

.ant-tree-treenode {
  width: 100%;
}

.ant-tree-node-content-wrapper {
  width: 100%;
  display: flex;
  background: transparent;
  position: static !important;
}

.ant-tree-iconEle {
  position: absolute;
  right: 22%;
}

.ant-tree-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 68%;
}

/* Move the icon to the right */
.ant-tree-title .ant-tree-switcher {
  order: 2;
}

/* Move the title to the left */
.ant-tree-title+div {
  order: 1;
  white-space: nowrap;
  background-color: transparent;
}

.ant-tree-title div {
  background-color: transparent;
}

.ant-tree-node-selected {
  background-color: transparent;
}

.ant-head .ant-card-head {
  margin-bottom: -15px !important;
  font-size: 16px
}

.ant-head .ant-card-body {
  padding: 5px 24px !important;

}

.ant-head {
  border: 1px solid #bab7b7 !important;
  border-radius: 3px !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 12px 0 rgba(0, 0, 0, .12) !important;
}

.accordian .accobody {
  font-size: 14px;
}

.accordian .accord_details {
  padding: 12px 16px 12px;
}

.accor-container {
  margin-bottom: 1px;
  /* Adjust the margin to control the spacing between accordions */
}

/* Styles for the accordion summary */
.accor-summary {
  position: relative;
  /* Required for absolute positioning */
  background-color: #f0f0f0;
  /* Adjust the background color as needed */
  border: 1px solid #e0e0e0;
  /* Add borders for the summary */
  border-radius: 5px;
  padding: 10px;
  /* Add padding to the summary for spacing */
}

/* Styles for the event info (event name) */
.event-info {

  margin-bottom: 1px;
  margin-left: 10px;
}

/* Styles for the status icons */
.status-icon {
  position: absolute;
  top: 15px;
  /* Adjust the top position as needed */
  right: 10px;
  /* Adjust the right position as needed */
  color: #FF5733;
  display: flex;
  gap: 6px;
  /* Optionally change the color of the status icon */
}

.error-message {
  font-size: 14px !important;
}

.card-icon {
  border: 1px solid #3f51b512;
  padding: 5px 7px 9px 7px;
  border-radius: 50%;
  background: #3f51b512;
  position: relative;
  top: -0.5rem;
}

.table .ant-table-thead>tr>th {
  padding: 12px 16px !important;
}

.table .ant-table-tbody>tr>td {
  padding: 10px 16px !important;
}

.selectDateBtn {
  border: 1px solid #bab7b7;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
}

.table {
  margin-bottom: 0px !important;
}

.table .ant-pagination {
  margin: 8px 0px !important;
}

.acc-check {
  border: 1px solid green;
  padding: 0px 3px 0px 3px;
  border-radius: 4px;
  background: green;
  height: 18px;
  width: 19px;

}

.acc-close {
  border: 1px solid red;
  padding: 0px 3px 0px 2.5px;
  border-radius: 4px;
  background: red;
  height: 18px;
  width: 19px;
}

.accor {
  border: 1px solid #1976d2;
  padding: 0px 3px 0px 3px;
  border-radius: 4px;
  background: #1976d2;
  height: 18px;
  width: 19px;
}

.Accordian-sec {
  margin: auto !important;
}

.close-btn svg {
  margin-top: -8px !important;
}

.error-table>tbody>tr>td {
  padding: 5px !important;
}

.stack-btn {
  border: 1px solid #1976d2;
  background: #1976d2;
  color: #fff;
}

.stack-btn:hover {
  border: 1px solid #1976d2 !important;
  background: #1976d2 !important;
  color: #fff !important;
}

/* Add this CSS in your stylesheet or within a style tag in your component */
.table-container {
  overflow-x: auto;
  /* Add horizontal scroll on small screens if necessary */
}

.custom-tree .ant-tree-node-content-wrapper:hover, .custom-tree .ant-tree-node-selected{
  background: transparent !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .ant-drawer-content-wrapper {
    width: 65% !important;
  }
}