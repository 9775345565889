.login-container {
  padding: 40px 45px 0px 45px;
  border: 1px solid #CED4DA;
  border-radius: 5px;
  background: #fff;
}

.login-label {
  color: #1E293B !important;
  font-size: 17px !important;
  font-weight: 600;
}

.login-input {
  padding: 10px !important;
  margin-top: 5px;
}

.login-btn {
  font-size: 20px !important;
  height: auto !important;
  padding: 5px 24px 8px 24px !important;
  border-radius: 6px;
}

.login-col {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.login-section .ant-col {
  padding: 0px;
}

@media (max-width: 992px) {
  body {
      overflow: hidden !important;
  }

  .login-col {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (min-width: 1400px) {
  .login-col {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
}